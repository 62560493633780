// Contract ABIs
import STAKING_CHANGEX_ABI from './staking-changex.json'
import CHANGEX_ABI from './changex.json'

// Contract addresses
const STAKING__CHANGEX_ADDRESS = '72c9a79baa83e698bf1dbf44d26e5bdca2d2bab1'
const CHANGEX_ADDRESS = 'bd3c617d271b3467bd9b83dda73c9288de2fb0c9'

export type STAKING_TOKEN_NAME = 'changex'

export const STAKING_TOKENS = {
  changex: {
    TOKEN_ADDRESS: CHANGEX_ADDRESS,
    TOKEN_ABI: CHANGEX_ABI,
    STAKING_ADDRESS: STAKING__CHANGEX_ADDRESS,
    STAKING_ABI: STAKING_CHANGEX_ABI
  },
  default: {
    TOKEN_ADDRESS: '',
    TOKEN_ABI: [],
    STAKING_ADDRESS: '',
    STAKING_ABI: []
  }
}
