import { contractCall, contractSend } from './utils'

const _balances = 'balances',
  _stake = 'stake',
  _unstake = 'unstake',
  _exit = 'exit',
  _earned = 'earned',
  _rewardPerSecond = 'rewardPerSecond',
  _rewardsDuration = 'rewardsDuration',
  _getReward = 'getReward',
  _reinvest = 'reinvest',
  _totalBalance = 'totalBalance'

export const balances = async (contract, user, walletExtension) => {
  const result = await contractCall(contract, _balances, [user], walletExtension.address)
  return result
}

export const stake = async (contract, amountWei, walletExtension) => {
  const result = await contractSend(contract, _stake, [amountWei], walletExtension.address)
  return result
}

export const unstake = async (contract, amountWei, walletExtension) => {
  const result = await contractSend(contract, _unstake, [amountWei], walletExtension.address)
  return result
}

export const exit = async (contract, walletExtension) => {
  const result = await contractSend(contract, _exit, [], walletExtension.address)
  return result
}

export const earned = async (contract, user, walletExtension) => {
  const result = await contractCall(contract, _earned, [user], walletExtension.address)
  return result
}

export const rewardPerSecond = async (contract, walletExtension) => {
  const result = await contractCall(contract, _rewardPerSecond, [], walletExtension.address)
  return result
}

export const rewardsDuration = async (contract, walletExtension) => {
  const result = await contractCall(contract, _rewardsDuration, [], walletExtension.address)
  return result
}

export const getReward = async (contract, walletExtension) => {
  const result = await contractSend(contract, _getReward, [], walletExtension.address)
  return result
}

export const reinvest = async (contract, walletExtension) => {
  const result = await contractSend(contract, _reinvest, [], walletExtension.address)
  return result
}

export const totalBalance = async (contract, walletExtension) => {
  const result = await contractCall(contract, _totalBalance, [], walletExtension.address)
  return result
}
