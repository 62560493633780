import { useCallback, useEffect, useState } from 'react'
import { useActiveWeb3React } from '../../hooks'
import useDebounce from '../../hooks/useDebounce'
import useIsWindowVisible from '../../hooks/useIsWindowVisible'
import { updateBlockNumber } from './actions'
import { useDispatch } from 'react-redux'
import { ChainId } from 'hydra/sdk'
import { Web3Provider } from '@ethersproject/providers'
import { prefix } from 'utils'

let interval: NodeJS.Timeout | undefined

export default function Updater(): null {
  const { library } = useActiveWeb3React()
  const chainId = ChainId.MAINNET
  const dispatch = useDispatch()

  const windowVisible = useIsWindowVisible()

  const [state, setState] = useState<{ chainId: number | undefined; blockNumber: number | null }>({
    chainId,
    blockNumber: null
  })

  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      setState(state => {
        if (chainId === state.chainId) {
          if (typeof state.blockNumber !== 'number') return { chainId, blockNumber }
          return { chainId, blockNumber: Math.max(blockNumber, state.blockNumber) }
        }
        return state
      })
    },
    [chainId, setState]
  )

  const fetchBlock = useCallback(
    (library: Web3Provider | undefined, isAttachListener: boolean) => {
      fetch(prefix + '/api/info')
        .then(r => {
          r.json().then(info => {
            blockNumberCallback(info.height)
            if (isAttachListener) {
              library?.on('block', () => blockNumberCallback(info.height))
            }
          })
        })
        .catch(error => console.error(`Failed to get block number for chainId: ${chainId}`, error))
    },
    [blockNumberCallback, chainId]
  )
  // attach/detach listeners
  useEffect(() => {
    if (!library || !chainId || !windowVisible) return undefined
    setState({ chainId, blockNumber: null })
    fetchBlock(library, true)
    if (!interval) {
      interval = setInterval(() => fetchBlock(library, false), 15000)
    }
    return () => {
      library.removeListener('block', blockNumberCallback)
    }
  }, [dispatch, chainId, library, blockNumberCallback, windowVisible, fetchBlock])

  const debouncedState = useDebounce(state, 100)

  useEffect(() => {
    if (!debouncedState.chainId || !debouncedState.blockNumber || !windowVisible) return
    dispatch(updateBlockNumber({ chainId: debouncedState.chainId, blockNumber: debouncedState.blockNumber }))
  }, [windowVisible, dispatch, debouncedState.blockNumber, debouncedState.chainId])

  return null
}
