import { useEffect, useState } from 'react'
import { AbiGetBalanceResolver, AbiToken, AbiHydraV2Factory } from '../../hydra/contracts/abi'
import { getContract } from '../../hydra/contracts/utils'
import { account, hydraweb3RPC } from 'hooks/useAddHydraAccExtension'
import { BigNumber } from '@ethersproject/bignumber'
import { UNI } from '../../constants'
import { addressGetBalancesResolver } from 'hydra/contracts/contractAddresses'
import { FACTORY_ADDRESS } from 'hydra/sdk'
import { allPairs, allPairsLength } from 'hydra/contracts/factoryFunctions'
import { balanceOf } from 'hydra/contracts/tokenFunctions'
import { useBlockNumber } from 'state/application/hooks'

export interface Result extends Array<any> {
  [key: string]: any
}

export interface CallState {
  readonly valid: boolean
  // the result, or undefined if loading or errored/no data
  readonly result: Result | undefined
  // true if the result has never been fetched
  readonly loading: boolean
  // true if the result is not for the latest block
  readonly syncing: boolean
  // true if the call was made and is synced, but the return data is invalid
  readonly error: boolean
}

const isEmpty = (obj: Record<string, any>): boolean => {
  if (!obj) {
    return true
  }
  return Object.keys(obj).length === 0
}

const getResult = (e: BigNumber) => {
  const result: Result | undefined = []
  result[0] = e
  result['balance'] = e
  return result
}

export function useBalancesOf(tokenAddresses: string[]): CallState[] {
  const [balances, setBalances] = useState<CallState[]>([])
  useEffect(() => {
    if (isEmpty(hydraweb3RPC) || !account?.loggedIn || tokenAddresses[0] === UNI[1].address) {
      setBalances([{ valid: true, result: undefined, loading: false, syncing: false, error: false }])
      return
    }
    if (tokenAddresses.length > 0) {
      ;(async () => {
        try {
          const resolver = getContract(hydraweb3RPC, addressGetBalancesResolver, AbiGetBalanceResolver)
          const tx = await resolver.call('getBalances', {
            methodArgs: [account.address, tokenAddresses],
            senderAddress: account.address
          })
          const r = tx.executionResult?.formattedOutput[0] as Array<BigNumber>

          setBalances(
            r.map<CallState>(e => ({
              valid: true,
              result: getResult(e),
              loading: false,
              syncing: false,
              error: false
            }))
          )
        } catch (e) {
          setBalances([{ valid: true, result: undefined, loading: false, syncing: false, error: false }])
        }
      })()
    }
    return () => {
      setBalances([])
    }
  }, [JSON.stringify(tokenAddresses), account, hydraweb3RPC, useBlockNumber])

  return balances
}

export function usePairBalancesOf(tokenAddresses: string[]): CallState[] {
  const [balances, setBalances] = useState<CallState[]>([])
  useEffect(() => {
    if (isEmpty(hydraweb3RPC) || !account?.loggedIn || tokenAddresses[0] === UNI[1].address) {
      setBalances([{ valid: true, result: undefined, loading: false, syncing: false, error: false }])
      return
    }
    if (tokenAddresses.length > 0) {
      ;(async () => {
        try {
          const factory = getContract(hydraweb3RPC, FACTORY_ADDRESS, AbiHydraV2Factory)
          const txPairsLength = await allPairsLength(factory, account)
          const pairsLenght = Number((txPairsLength.executionResult?.formattedOutput[0]).toString())
          const pairs: string[] = []
          const _balances: CallState[] = []
          for (let i = 0; i < pairsLenght; i++) {
            const txPair = await allPairs(factory, account, i)
            const pairAddress = txPair.executionResult.formattedOutput[0]
            pairs.push('0x' + pairAddress)
          }
          for (let i = 0; i < tokenAddresses.length; i++) {
            const address = tokenAddresses[i]
            let isBalance = false
            for (let y = 0; y < pairs.length; y++) {
              const pairAddr = pairs[y]
              if (address === pairAddr) {
                const txBalance = await balanceOf(
                  getContract(hydraweb3RPC, pairAddr, AbiToken),
                  account.address,
                  account
                )
                const pairBalance = txBalance.executionResult.formattedOutput[0]
                _balances.push({
                  valid: true,
                  result: getResult(pairBalance),
                  loading: false,
                  syncing: false,
                  error: false
                })
                isBalance = true
              }
            }
            if (!isBalance) {
              _balances.push({
                valid: true,
                result: undefined,
                loading: false,
                syncing: false,
                error: false
              })
            }
          }
          setBalances(_balances)
        } catch (e) {
          setBalances([])
        }
      })()
    }
    return () => {
      setBalances([])
    }
  }, [JSON.stringify(tokenAddresses), account, hydraweb3RPC, useBlockNumber])

  return balances
}
