'use strict'

import { BytesLike, concat, hexDataLength, hexDataSlice } from '@ethersproject/bytes'
import { keccak256 } from '@ethersproject/keccak256'

import { Logger } from '@ethersproject/logger'
import { version } from './_version'
const logger = new Logger(version)

export function getCreate2Address(from: string, salt: BytesLike, initCodeHash: BytesLike): string {
  if (hexDataLength(salt) !== 32) {
    logger.throwArgumentError('salt must be 32 bytes', 'salt', salt)
  }

  if (hexDataLength(initCodeHash) !== 32) {
    logger.throwArgumentError('initCodeHash must be 32 bytes', 'initCodeHash', initCodeHash)
  }

  return hexDataSlice(keccak256(concat(['0xff', from, salt, initCodeHash])), 12)
}
