import { Currency, Pair, TokenAmount, ChainId } from 'hydra/sdk'
import { useMemo } from 'react'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { useGetReserves } from 'hooks/usePairFunctions'

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}

export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const chainId = ChainId.MAINNET

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => {
        return [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
      }),
    [chainId, currencies]
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined
      }),
    [tokens]
  )

  const results = useGetReserves(pairAddresses)

  return useMemo(() => {
    return (
      results
        // .filter((result: any, index) => result?.reserves?.reserve0 && result?.reserves?.reserve1)
        .map((result, i) => {
          if (!tokens[i] || !result?.result || !result?.result?.reserve0 || !result?.result?.reserve1)
            return [PairState.NOT_EXISTS, null]

          const { result: reserves, loading } = result
          const tokenA = tokens[i][0]
          const tokenB = tokens[i][1]

          if (loading) return [PairState.LOADING, null]
          if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
          if (!reserves) return [PairState.NOT_EXISTS, null]
          const { reserve0, reserve1 } = reserves

          const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

          return [
            PairState.EXISTS,
            new Pair(new TokenAmount(token0, reserve0?.toString()), new TokenAmount(token1, reserve1?.toString()))
          ]
        })
    )
  }, [results, tokens])
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]])[0]
}
