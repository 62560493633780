import React from 'react'
import { AutoColumn } from 'components/Column'
import { RowBetween } from 'components/Row'
import { AdvancedDetailsFooter } from 'components/swap/AdvancedSwapDetailsDropdown'
import { Currency } from 'hydra/sdk'
import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { TYPE } from 'theme'

const DetailsFooterWrapper = styled.div<{ show: boolean }>`
  width: 100%;
  max-width: 400px;
  margin-top: -2rem;
  transform: ${({ show }) => (show ? 'translateY(0%)' : 'translateY(-100%)')};
  transition: transform 300ms ease-in-out;

  @media (max-width: 720px) {
    margin-top: 0;
  }
`

interface Props {
  accountHydra: string
  apy: string | null
  apyPercentage: string | null
  currency: Currency | undefined
}

export default function DetailsFooter({ accountHydra, apy, apyPercentage, currency }: Props) {
  const theme = useContext(ThemeContext)

  return (
    <DetailsFooterWrapper show={!!(accountHydra && apy)}>
      <AdvancedDetailsFooter show={!!(accountHydra && apy)}>
        <AutoColumn gap="md">
          <RowBetween padding="0 16px">
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Projected Annual Reward
            </TYPE.black>
            <TYPE.black color={theme.text1} fontSize={14}>
              {apy} {currency?.symbol}
            </TYPE.black>
          </RowBetween>
          <RowBetween padding="0 16px">
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              APY
            </TYPE.black>
            <TYPE.black color={theme.text1} fontSize={14}>
              {apyPercentage}%
            </TYPE.black>
          </RowBetween>
        </AutoColumn>
      </AdvancedDetailsFooter>
    </DetailsFooterWrapper>
  )
}
