import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { StakeTabs, SwapPoolTabs } from 'components/NavigationTabs'
import Row, { RowBetween } from 'components/Row'
import AppBody from 'pages/AppBody'
import styled from 'styled-components'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { useCurrency } from 'hooks/Tokens'
import { AutoColumn } from 'components/Column'
import { tryParseAmount } from '../../state/swap/hooks'
import useAddHydraAccExtension, { hydraweb3RPC, useHydraAccount } from 'hooks/useAddHydraAccExtension'
import { Text } from 'rebass'
import ConnectWalletModal from 'components/ConnectWalletModal'
import { ButtonLight, ButtonPrimary, ButtonSecondary } from 'components/Button'
import { useWalletModalToggle } from 'state/application/hooks'
import { RouteComponentProps } from 'react-router-dom'
import { useCurrencyBalance } from 'state/wallet/hooks'
import useHydra from 'hooks/useHydra'
import { ethers } from 'ethers'
import { getContract } from 'hydra/contracts/utils'
import { STAKING_TOKEN_NAME } from 'constants/abis/staking'
import { balances, earned, stake, reinvest, unstake, getReward } from 'hydra/contracts/stakingFunctions'
import { allowance, approve } from 'hydra/contracts/tokenFunctions'
import Modal from 'components/Modal'
import TransactionConfirmationModal, { TransactionErrorContent } from '../../components/TransactionConfirmationModal'
import { useHasPendingApproval, useTransactionAdder } from 'state/transactions/hooks'
import { useStakingAPY } from 'hooks/useStakingAPY'
import DetailsFooter from 'components/Stake/DetailsFooter'
import { useStakingCurrency } from 'hooks/useStakingCurrency'

const Wrapper = styled.div`
  position: relative;
  padding: 1rem;
`

export default function Stake({
  match: {
    params: { currencyName }
  }
}: RouteComponentProps<{ currencyName: STAKING_TOKEN_NAME }>) {
  const { walletExtension, hydraweb3Extension } = useHydra()
  useAddHydraAccExtension(walletExtension, hydraweb3Extension)
  const [account] = useHydraAccount()
  const accountHydra = account?.address
  const { STAKING_ADDRESS, STAKING_ABI, TOKEN_ADDRESS, TOKEN_ABI } = useStakingCurrency(currencyName)
  const addTransaction = useTransactionAdder()

  const [contract, setContract] = useState(null)
  const [tokenContract, setTokenContract] = useState(null)
  const [allowanceModal, setAllowanceModal] = useState(false)
  const [pendingApprovalModal, setPendingApprovalModal] = useState(false)
  const [stakedBalance, setStakedBalance] = useState(ethers.constants.Zero)
  const [profit, setProfit] = useState(ethers.constants.Zero)
  const [input, setInput] = useState('')

  const [showConnectWalletModal, toggleConnectWalletModal] = useState(false)
  const currency = useCurrency(TOKEN_ADDRESS)
  const currencyBalance = useCurrencyBalance(accountHydra ?? undefined, currency ?? undefined)

  // Transaction modal state
  const [showTransactionModal, setShowTransactionModal] = useState(false)
  const [pendingText, setPendingText] = useState('')
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [hash, setHash] = useState('')
  const [transactionErrorMessage, setTransactionErrorMessage] = useState('')

  const inputAmount = useMemo(() => tryParseAmount(input, currency ?? undefined), [currency, input])
  const stakedAmount = useMemo(
    () => tryParseAmount(ethers.utils.formatEther(stakedBalance) || undefined, currency ?? undefined),
    [currency, stakedBalance]
  )

  const { apy, apyPercentage } = useStakingAPY(ethers.utils.parseEther(inputAmount?.toExact() || '1'), currencyName)

  const pendingApproval = useHasPendingApproval(TOKEN_ADDRESS, STAKING_ADDRESS)

  const handleTypeInput = useCallback(
    (value: string) => {
      setInput(value)
    },
    [setInput]
  )

  const getUserBalances = useCallback(
    contract => {
      if (contract && accountHydra) {
        balances(contract, accountHydra, account)
          .then(({ executionResult }) => {
            setStakedBalance(ethers.BigNumber.from('0x' + executionResult.output))
          })
          .catch(console.log)

        earned(contract, accountHydra, account)
          .then(({ executionResult }) => {
            if (executionResult.excepted !== 'Revert') {
              setProfit(ethers.BigNumber.from('0x' + executionResult.output))
            }
          })
          .catch(console.log)
      }
    },
    [accountHydra, account]
  )

  useEffect(() => {
    setInput('')
  }, [setInput])

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (contract) {
      interval = setInterval(() => {
        getUserBalances(contract)
      }, 10000)
    }
    return () => clearInterval(interval)
  }, [contract, getUserBalances])

  useEffect(() => {
    if (accountHydra) {
      const contract = getContract(hydraweb3RPC, STAKING_ADDRESS, STAKING_ABI)
      const tokenContract = getContract(hydraweb3RPC, TOKEN_ADDRESS, TOKEN_ABI)
      setContract(contract)
      setTokenContract(tokenContract)
    }
  }, [accountHydra, account, TOKEN_ADDRESS])

  useEffect(() => {
    if (account && contract) {
      getUserBalances(contract)
    }
  }, [account, contract, getUserBalances])

  useEffect(() => {
    if (!pendingApproval) {
      setPendingApprovalModal(false)
    }
  }, [pendingApproval])

  const hideTransactionModal = () => {
    setShowTransactionModal(false)
    setAttemptingTxn(false)
    setHash('')
    setPendingText('')
  }

  const toggleWalletModal = useWalletModalToggle()

  const handleMaxInput = useCallback(() => {
    currencyBalance && setInput(currencyBalance.toExact())
  }, [currencyBalance, setInput])

  const _toggleWalletModal = () => {
    if (!window.ReactNativeWebView) toggleConnectWalletModal(true)
    toggleWalletModal()
  }
  const checkAllowance = useCallback(
    async amount => {
      return allowance(tokenContract, account, STAKING_ADDRESS)
        .then(({ executionResult }) => {
          const allowedBN = ethers.BigNumber.from('0x' + executionResult.output)
          const enough = allowedBN.gte(amount)

          if (!enough) {
            setAllowanceModal(true)
          }

          return enough
        })
        .catch(console.log)
    },
    [tokenContract, account]
  )

  const handleStartTransaction = useCallback(
    summary => {
      setShowTransactionModal(true)
      setPendingText(summary)
      setAttemptingTxn(true)
    },
    [setShowTransactionModal, setPendingText, setAttemptingTxn]
  )

  const handleSubmittedTransaction = useCallback(
    (res, summary) => {
      res.hash = res.txid
      setShowTransactionModal(true)
      setHash(res.txid)
      setAttemptingTxn(false)
      addTransaction(res, {
        summary,
        approval: { tokenAddress: TOKEN_ADDRESS, spender: STAKING_ADDRESS }
      })
    },
    [setShowTransactionModal, setHash, setAttemptingTxn, addTransaction, TOKEN_ADDRESS]
  )

  const handleFailedTransaction = useCallback(
    err => {
      console.log(err)
      setShowTransactionModal(true)
      setHash('')
      setAttemptingTxn(false)
      setTransactionErrorMessage(err.message)
    },
    [setHash, setAttemptingTxn, setTransactionErrorMessage]
  )

  const handleApprove = useCallback(() => {
    setAllowanceModal(false)
    handleStartTransaction(`Approve interaction with ${currency?.name} tokens`)
    approve(STAKING_ADDRESS, tokenContract, account, ethers.constants.MaxUint256)
      .then(res => {
        handleSubmittedTransaction(res, `Approve interaction with ${currency?.name} tokens`)
      })
      .catch(handleFailedTransaction)
  }, [tokenContract, currency, handleSubmittedTransaction, account, handleFailedTransaction, handleStartTransaction])

  const handleStake = useCallback(async () => {
    if (contract) {
      if (pendingApproval) {
        setPendingApprovalModal(true)
        return
      }

      const value = ethers.utils.parseUnits(input, 18)
      const enough = await checkAllowance(value)
      if (!enough || !inputAmount || !currencyBalance || currencyBalance?.lessThan(inputAmount)) {
        return
      }

      handleStartTransaction(`Stake ${input} ${currency?.symbol}`)
      stake(contract, value, account)
        .then(res => {
          handleSubmittedTransaction(res, `Stake ${input} ${currency?.symbol}`)
          setInput('')
        })
        .catch(handleFailedTransaction)
    }
  }, [
    contract,
    input,
    pendingApproval,
    checkAllowance,
    currency,
    currencyBalance,
    inputAmount,
    setInput,
    account,
    handleSubmittedTransaction,
    handleFailedTransaction,
    handleStartTransaction
  ])

  const handleUnstake = useCallback(() => {
    if (contract) {
      const value = ethers.utils.parseUnits(input, 18)
      if (!inputAmount || !stakedAmount || stakedAmount?.lessThan(inputAmount)) {
        return
      }

      handleStartTransaction(`Unstake ${input} ${currency?.symbol}`)
      unstake(contract, value, account)
        .then(res => {
          handleSubmittedTransaction(res, `Unstake ${input} ${currency?.symbol}`)
          setInput('')
        })
        .catch(handleFailedTransaction)
    }
  }, [
    contract,
    input,
    account,
    currency,
    handleSubmittedTransaction,
    inputAmount,
    stakedAmount,
    handleFailedTransaction,
    handleStartTransaction
  ])

  const handleGetReward = useCallback(() => {
    if (contract) {
      if (profit.lte(ethers.constants.Zero)) {
        return
      }

      handleStartTransaction(`Withdraw ${ethers.utils.formatEther(profit)} ${currency?.symbol}`)
      getReward(contract, account)
        .then(res => {
          handleSubmittedTransaction(res, `Withdraw ${ethers.utils.formatEther(profit)} ${currency?.symbol}`)
        })
        .catch(handleFailedTransaction)
    }
  }, [contract, profit, account, currency, handleSubmittedTransaction, handleFailedTransaction, handleStartTransaction])

  const handleReinvest = useCallback(() => {
    if (contract) {
      if (profit.lte(ethers.constants.Zero)) {
        return
      }

      handleStartTransaction(`Restake ${ethers.utils.formatEther(profit)} ${currency?.symbol}`)
      reinvest(contract, account)
        .then(res => {
          handleSubmittedTransaction(res, `Restake ${ethers.utils.formatEther(profit)} ${currency?.symbol}`)
        })
        .catch(handleFailedTransaction)
    }
  }, [contract, profit, account, currency, handleSubmittedTransaction, handleFailedTransaction, handleStartTransaction])

  return (
    <>
      <SwapPoolTabs active={'staking'} />
      <AppBody>
        <StakeTabs />
        <Wrapper>
          <CurrencyInputPanel
            value={input}
            onUserInput={handleTypeInput}
            onMax={handleMaxInput}
            showMaxButton={Boolean(!(currencyBalance && inputAmount?.equalTo(currencyBalance)))}
            label="Amount"
            placeholder=""
            currency={currency}
            disableCurrencySelect={true}
            showCommonBases
            id="stake-input-field"
          />
          {accountHydra ? (
            <>
              <Row justify="start">
                <Text
                  textAlign="center"
                  color={'#C3C5CB'}
                  fontSize="14px"
                  style={{ marginTop: '0.5rem', marginLeft: '16px', cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => setInput(ethers.utils.formatEther(stakedBalance))}
                >
                  Staked balance: {stakedAmount ? stakedAmount?.toSignificant(8) : '0'}
                </Text>
              </Row>
              <AutoColumn gap="md" style={{ marginTop: '16px' }}>
                <RowBetween>
                  <ButtonPrimary
                    disabled={!inputAmount || !currencyBalance || currencyBalance?.lessThan(inputAmount)}
                    onClick={handleStake}
                    padding="12px"
                    borderRadius="20px!important"
                    style={{ width: '48%', fontSize: '22px' }}
                  >
                    {inputAmount && (!currencyBalance || currencyBalance?.lessThan(inputAmount))
                      ? 'Insufficient balance'
                      : 'Stake'}
                  </ButtonPrimary>

                  <ButtonSecondary
                    disabled={!inputAmount || !stakedAmount || stakedAmount?.lessThan(inputAmount)}
                    onClick={handleUnstake}
                    padding="12px"
                    borderRadius="20px!important"
                    style={{ width: '48%', fontSize: '22px' }}
                  >
                    {inputAmount && (!stakedAmount || stakedAmount?.lessThan(inputAmount))
                      ? 'Insufficient balance'
                      : 'Unstake'}
                  </ButtonSecondary>
                </RowBetween>
              </AutoColumn>
              <AutoColumn gap="md" style={{ marginTop: '24px', borderTop: '1px solid #2C2F36' }}>
                <Row justify="center" marginTop="16px">
                  <Text textAlign="center" color={'#C3C5CB'} fontSize="18px">
                    Current profit: {profit.eq(ethers.constants.Zero) ? '0' : ethers.utils.formatEther(profit)}
                  </Text>
                </Row>
                <Row justify="center">
                  <ButtonLight
                    disabled={profit.lte(ethers.constants.Zero)}
                    padding="6px"
                    borderRadius="20px!important"
                    style={{ width: '120px', marginRight: '18px' }}
                    onClick={handleReinvest}
                  >
                    Restake
                  </ButtonLight>
                  <ButtonLight
                    disabled={profit.lte(ethers.constants.Zero)}
                    padding="4px"
                    borderRadius="20px!important"
                    style={{ width: '120px' }}
                    onClick={handleGetReward}
                  >
                    Withdraw
                  </ButtonLight>
                </Row>
              </AutoColumn>
            </>
          ) : (
            <Row marginTop="16px">
              <ButtonLight onClick={_toggleWalletModal}>Connect Wallet</ButtonLight>
            </Row>
          )}
          {!accountHydra && (
            <ConnectWalletModal
              showConnectWalletModal={showConnectWalletModal}
              toggleConnectWalletModal={toggleConnectWalletModal}
            />
          )}
        </Wrapper>
      </AppBody>

      <DetailsFooter
        accountHydra={accountHydra}
        apy={apy}
        apyPercentage={apyPercentage}
        currency={currency ?? undefined}
      />

      <Modal isOpen={allowanceModal} onDismiss={() => setAllowanceModal(false)} maxHeight={90}>
        <Wrapper>
          <Text textAlign="center">
            You need to approve that our platform can interact with your {currency?.name} tokens.
          </Text>
          <ButtonPrimary marginTop="16px" fontSize="18px" padding="5px" onClick={handleApprove}>
            Approve
          </ButtonPrimary>
        </Wrapper>
      </Modal>

      <Modal isOpen={pendingApprovalModal} onDismiss={() => setPendingApprovalModal(false)} maxHeight={90}>
        <Wrapper>
          <Text textAlign="center">Please wait till your approval transaction is processed.</Text>
        </Wrapper>
      </Modal>

      <TransactionConfirmationModal
        isOpen={showTransactionModal}
        onDismiss={hideTransactionModal}
        attemptingTxn={attemptingTxn}
        hash={hash}
        content={() =>
          transactionErrorMessage && (
            <TransactionErrorContent onDismiss={hideTransactionModal} message={transactionErrorMessage} />
          )
        }
        pendingText={pendingText}
      />
    </>
  )
}
