import { ethers, BigNumber } from 'ethers'
import { rewardPerSecond, rewardsDuration, totalBalance } from 'hydra/contracts/stakingFunctions'
import { balanceOf } from 'hydra/contracts/tokenFunctions'
import { useEffect, useState } from 'react'
import { getContract } from 'hydra/contracts/utils'
import { hydraweb3RPC, useHydraAccount } from 'hooks/useAddHydraAccExtension'
import { useStakingCurrency } from './useStakingCurrency'
import { STAKING_TOKEN_NAME } from 'constants/abis/staking'

export function useStakingAPY(amount: BigNumber, currencyName: STAKING_TOKEN_NAME) {
  const [account] = useHydraAccount()
  const [rewardPerSecondVal, setRewardPerSecondVal] = useState<BigNumber | null>(null)
  const [rewardsDurationVal, setRewardsDurationVal] = useState<BigNumber | null>(null)
  const [tokenBalance, setTokenBalance] = useState<BigNumber | null>(null)
  const [totalStakedBalance, setTotalStakedBalance] = useState<BigNumber | null>(null)
  const { STAKING_ADDRESS, STAKING_ABI, TOKEN_ADDRESS, TOKEN_ABI } = useStakingCurrency(currencyName)

  useEffect(() => {
    if (account) {
      const contract = getContract(hydraweb3RPC, STAKING_ADDRESS, STAKING_ABI)
      const tokenContract = getContract(hydraweb3RPC, TOKEN_ADDRESS, TOKEN_ABI)

      rewardPerSecond(contract, account)
        .then(({ executionResult }) => {
          setRewardPerSecondVal(ethers.BigNumber.from('0x' + executionResult.output))
        })
        .catch(console.log)

      rewardsDuration(contract, account)
        .then(({ executionResult }) => {
          setRewardsDurationVal(ethers.BigNumber.from('0x' + executionResult.output))
        })
        .catch(console.log)

      balanceOf(tokenContract, STAKING_ADDRESS, account)
        .then(({ executionResult }) => {
          setTokenBalance(ethers.BigNumber.from('0x' + executionResult.output))
        })
        .catch(console.log)

      totalBalance(contract, account)
        .then(({ executionResult }) => {
          setTotalStakedBalance(ethers.BigNumber.from('0x' + executionResult.output))
        })
        .catch(console.log)
    }
  }, [account, currencyName])

  const { apy, apyPercentage } = calculateAPY(
    amount,
    rewardPerSecondVal,
    totalStakedBalance,
    rewardsDurationVal,
    tokenBalance
  )

  return { apy, apyPercentage }
}

function calculateAPY(
  amount: BigNumber | null,
  rewardPerSecond: BigNumber | null,
  totalStakedBalance: BigNumber | null,
  rewardsDuration: BigNumber | null,
  tokenBalance: BigNumber | null
) {
  if (!amount || !rewardPerSecond || !totalStakedBalance || !rewardsDuration || !tokenBalance) {
    return { apy: null, apyPercentage: null }
  }

  try {
    if (rewardsDuration.eq(ethers.constants.Zero) || amount.eq(ethers.constants.Zero)) {
      return { apy: '0.00', apyPercentage: '0.00' }
    }

    const nonAddedReward = tokenBalance.sub(totalStakedBalance)
    if (rewardPerSecond.eq(ethers.constants.Zero)) {
      if (nonAddedReward.lt(rewardsDuration)) {
        return { apy: '0.00', apyPercentage: '0.00' }
      }

      rewardPerSecond = nonAddedReward.div(rewardsDuration)
    }

    const projectedReward = rewardPerSecond
      .mul(86400)
      .mul(365)
      .mul(amount)
      .div(totalStakedBalance.add(amount))
    const amountString = ethers.utils.formatUnits(amount, 18)
    const projectedRewardString = ethers.utils.formatUnits(projectedReward, 18)
    const projectedAmountNumber = Number(projectedRewardString)
    const amountNumber = Number(amountString)
    const projectedAPR = projectedAmountNumber / amountNumber
    const projectedAPY = compoundInterest(amountNumber, 1, projectedAPR, 31536000)
    return {
      apy: projectedAPY.toFixed(2),
      apyPercentage: ((projectedAPY * 100) / amountNumber).toFixed(2)
    }
  } catch (error) {
    return { apy: '0.00', apyPercentage: '0.00' }
  }
}

function compoundInterest(principal: number, time: number, rate: number, numberOfCompoundingsPerTime: number): number {
  const amount = principal * Math.pow(1 + rate / numberOfCompoundingsPerTime, numberOfCompoundingsPerTime * time)
  const interest = amount - principal
  return interest
}
