import { BigNumber } from '@ethersproject/bignumber'
import { BigNumberish } from 'ethers'
import { account, hydraweb3RPC } from 'hooks/useAddHydraAccExtension'
import { AbiToken } from 'hydra/contracts/abi'
import { totalSupply } from 'hydra/contracts/tokenFunctions'
import { getContract } from 'hydra/contracts/utils'
import { Token, TokenAmount } from 'hydra/sdk'
import { useEffect, useState } from 'react'
import { useTokenContract } from '../hooks/useContract'
import { useSingleCallResult } from '../state/multicall/hooks'

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useTotalSupply(token?: Token): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

  const totalSupply: BigNumber = useSingleCallResult(contract, 'totalSupply')?.result?.[0]

  return token && totalSupply ? new TokenAmount(token, totalSupply.toString()) : undefined
}

export function useTotalSupplyHydra(token?: Token): TokenAmount | undefined {
  const [tS, setTS] = useState<BigNumberish | undefined>(undefined)
  useEffect(() => {
    if (!token || !account?.loggedIn) {
      return
    }
    ;(async () => {
      try {
        const contract = getContract(hydraweb3RPC, token?.address.toLowerCase(), AbiToken)
        const result = await totalSupply(contract, account)
        setTS(result.executionResult?.formattedOutput?.[0])
      } catch (e) {
        console.log(e)
        setTS(undefined)
      }
    })()
  }, [JSON.stringify(token)])
  return token && tS ? new TokenAmount(token, tS.toString()) : undefined
}
