export const getContract = (hydraweb3Extension, contractAddress, abi) => {
  try {
    const contract = hydraweb3Extension.Contract(contractAddress, abi)

    return contract
  } catch (e) {}
}

export const contractCall = async (contract, method, methodArgs, senderAddress) => {
  const tx = await contract.call(method, {
    methodArgs,
    senderAddress
  })

  return tx
}

export const contractSend = async (contract, method, methodArgs, senderAddress, gasLimit = 250000, amountHYDRA = 0) => {
  const tx = await contract.send(method, {
    methodArgs,
    gasLimit,
    senderAddress,
    amount: amountHYDRA
  })

  return tx
}

const ZERO = '0'
const POINT = '.'
const ZERO_POINT = ZERO + POINT
export const getAmount = (amount, decimals = 1e8) => {
  if (typeof amount === 'string') {
    let amount0 = amount
    let idx = amount0.indexOf(POINT)
    decimals = 8
    if (idx > -1) {
      if (amount0 === POINT) {
        amount0 = ZERO_POINT
        idx = 1
      }
      if (amount0.startsWith(ZERO_POINT)) {
        let isZeros = true
        for (let i = idx + 1; i < amount0.length; i++) {
          if (amount0[i] !== ZERO) {
            isZeros = false
            break
          }
        }
        if (isZeros) {
          return undefined
        }
      }
      amount0 = amount0.replace(POINT, '').padEnd(idx + decimals, ZERO)
      if (idx !== 0) {
        for (let i = 0; i < amount0.length - 1; i++) {
          if (amount0.startsWith(ZERO)) {
            amount0 = amount0.slice(1)
            continue
          }
          break
        }
      }
      return amount0
    } else {
      if (amount0.startsWith(ZERO)) {
        return amount0[0]
      }
      return amount0.padEnd(amount0.length + decimals, ZERO)
    }
  }

  const result = amount * decimals
  return result.toString()
}

export const fromAmount = (amount, decimals = 1e8) => {
  const result = amount / decimals
  return result
}

export const getAmountHYDRA = amount => {
  return getAmount(amount)
}

export const fromAmountHYDRA = amount => {
  return fromAmount(amount)
}
