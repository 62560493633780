import { contractSend } from './utils'

const _deposit = 'deposit',
  _withdraw = 'withdraw'

export const deposit = async (whydra, walletExtension, amountHYDRADesired) => {
  const result = await contractSend(whydra, _deposit, [], walletExtension.address, 250000, amountHYDRADesired)
  return result
}

export const withdraw = async (whydra, walletExtension, amountDesired) => {
  const result = await contractSend(whydra, _withdraw, [amountDesired], walletExtension.address)
  return result
}
