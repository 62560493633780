import { account, hydraweb3RPC } from 'hooks/useAddHydraAccExtension'
import { AbiToken } from 'hydra/contracts/abi'
import { getContract } from 'hydra/contracts/utils'
import { Token, TokenAmount } from 'hydra/sdk'
import { useEffect, useMemo, useState } from 'react'

import { useTokenContract } from '../hooks/useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import { allowance as tokenAllowance } from 'hydra/contracts/tokenFunctions'
import { Result } from 'state/hydra/hrc20calls'

export function useTokenAllowance(token?: Token, owner?: string, spender?: string): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)

  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(contract, 'allowance', inputs).result

  return useMemo(() => (token && allowance ? new TokenAmount(token, allowance.toString()) : undefined), [
    token,
    allowance
  ])
}

export function useTokenAllowanceHydra(token?: Token, owner?: string, spender?: string): TokenAmount | undefined {
  const contract = getContract(hydraweb3RPC, token?.address.toLowerCase(), AbiToken)

  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const [allowance, setAllowance] = useState<Result | undefined>(undefined)
  useEffect(() => {
    if (!account?.loggedIn) {
      return
    }
    ;(async () => {
      const result = await tokenAllowance(contract, account, spender)
    })()
  }, [token?.address, hydraweb3RPC, account])

  return useMemo(() => (token && allowance ? new TokenAmount(token, allowance.toString()) : undefined), [
    token,
    allowance
  ])
}
