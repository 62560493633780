import { HYDRA, WHYDRA, CurrencyAmount, Token, Trade, JSBI, ChainId, Percent } from 'hydra/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Column'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import BetterTradeLink, { DefaultVersionLink } from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'
import SwapHeader from '../../components/swap/SwapHeader'

import { BIPS_BASE, INITIAL_ALLOWED_SLIPPAGE, INVALID_ADDRESS_HYDRA } from '../../constants'
import { getTradeVersion } from '../../data/V1'
import { useCurrency, useAllTokens } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { DEFAULT_VERSION, Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance, useUserSingleHopOnly } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { ClickableText } from '../Pool/styleds'
import Loader from '../../components/Loader'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { isTradeBetter } from 'utils/trades'
import useHydra from '../../hooks/useHydra'
import useAddHydraAccExtension, { account, hydraweb3RPC, isEmptyObj } from '../../hooks/useAddHydraAccExtension'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { AbiToken, AbiHydraV2Router01, AbiWrappedHydra, AbiHydraV2Factory } from 'hydra/contracts/abi'
import { addressRouter } from 'hydra/contracts/contractAddresses'
import { getContract } from 'hydra/contracts/utils'
import {
  swapExactHYDRAForTokens,
  swapExactTokensForHYDRA,
  swapExactTokensForTokens
} from 'hydra/contracts/routerFunctions'
import { deposit, withdraw } from 'hydra/contracts/whydraFunctions'
import { allowance, approve } from 'hydra/contracts/tokenFunctions'
import { BigNumber, ethers } from 'ethers'
import { getPair } from 'hydra/contracts/factoryFunctions'
import { useTransactionAdder } from 'state/transactions/hooks'
import { walletErrorCatch } from 'state/hydra/walletErrorCatch'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import ConnectWalletModal from '../../components/ConnectWalletModal'

export default function Swap() {
  const loadedUrlParams = useDefaultsFromURLSearch()
  const { walletExtension, hydraweb3Extension } = useHydra()
  useAddHydraAccExtension(walletExtension, hydraweb3Extension)

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]

  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !Boolean(token.address in defaultTokens)
    })

  const accountHydra = account?.address

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()

  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError
  } = useDerivedSwapInfo()

  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE

  const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion()
  const tradesByVersion = {
    [Version.v1]: v1Trade,
    [Version.v2]: v2Trade
  }

  const [tokenA, setTokenA] = useState<any>({})
  const [showConnectWalletModal, toggleConnectWalletModal] = useState(false)
  const [tokenB, setTokenB] = useState<any>({})
  const [swapHydra, setSwapHydra] = useState<any>({})
  const [approvalHydra, setApprovalHydra] = useState<ApprovalState>(ApprovalState.UNKNOWN)

  const _toggleWalletModal = () => {
    if (!window.ReactNativeWebView) toggleConnectWalletModal(true)
    toggleWalletModal()
  }

  
  useEffect(() => {
    if (isEmptyObj(hydraweb3RPC) || isEmptyObj(account)) {
      return
    }
    ;(async () => {
      if (currencies[Field.INPUT] && currencies[Field.OUTPUT]) {
        const curA = currencies[Field.INPUT] as WrappedTokenInfo
        const curB = currencies[Field.OUTPUT] as WrappedTokenInfo
        try {
          if (
            (curA.name === HYDRA.name && curB.name === WHYDRA[ChainId.MAINNET].name) ||
            (curB.name === HYDRA.name && curA.name === WHYDRA[ChainId.MAINNET].name)
            ) {
              const isHydraA = curA.name === HYDRA.name
              const whydra = getContract(hydraweb3RPC, WHYDRA[1].address.toLowerCase(), AbiWrappedHydra)
              setTokenA(isHydraA ? {} : whydra)
            setTokenB(isHydraA ? whydra : {})
            setApprovalHydra(ApprovalState.UNKNOWN)
            setSwapHydra({})
            return
          }
          const isWrapA = curA.name === HYDRA.name && curB.name !== HYDRA.name && curB.name !== WHYDRA[1].name
          const isWrapB = curB.name === HYDRA.name && curA.name !== HYDRA.name && curA.name !== WHYDRA[1].name
          const token0Address = isWrapA ? WHYDRA[1].address.toLowerCase() : curA.tokenInfo.address
          const token1Address = isWrapB ? WHYDRA[1].address.toLowerCase() : curB.tokenInfo.address
          const _tokenA = getContract(hydraweb3RPC, token0Address, isWrapA ? AbiToken : AbiWrappedHydra)
          const _tokenB = getContract(hydraweb3RPC, token1Address, isWrapB ? AbiToken : AbiWrappedHydra)
          if (!isWrapA) {
            const token0Address = isWrapA ? WHYDRA[1].address.toLowerCase() : curA.tokenInfo.address
            const token = getContract(hydraweb3RPC, token0Address, isWrapA ? AbiToken : AbiWrappedHydra)
            const router = getContract(hydraweb3RPC, addressRouter, AbiHydraV2Router01)
            const allowanceResult = await allowance(token, account, router.address)
            const allowanceFormated = allowanceResult.executionResult.formattedOutput[0]
            setApprovalHydra(
              BigNumber.from(allowanceFormated.toString()).gt(0) ? ApprovalState.APPROVED : ApprovalState.NOT_APPROVED
            )
          }
          setTokenA(_tokenA)
          setTokenB(_tokenB)
          setSwapHydra(isWrapA ? _tokenA : isWrapB ? _tokenB : {})
        } catch (e) {}
      }
    })()
  }, [currencies[Field.INPUT], currencies[Field.OUTPUT], account])
  
  const trade =
    showWrap ? undefined : tradesByVersion[toggledVersion]
  const defaultTrade = showWrap ? undefined : tradesByVersion[DEFAULT_VERSION]
  const route = trade?.route
  
  const betterTradeLinkV2: Version | undefined =
    toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade) ? Version.v2 : undefined

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()

  const isValid = !swapInputError

  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const approveCallbackHydra = async () => {
    try {
      const router = getContract(hydraweb3RPC, addressRouter, AbiHydraV2Router01)

      const tx = await approve(router.address, tokenA, account, ethers.constants.MaxUint256)

      if (walletErrorCatch(tx)) {
        return
      }
      setApprovalHydra(ApprovalState.APPROVED)
    } catch (e) {
      alert('approve could not proceed, please try again')
    }
  }

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(undefined, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const [singleHopOnly] = useUserSingleHopOnly()

  const addTransaction = useTransactionAdder()
  
  const transactions = useSelector<AppState, any>(state => state.transactions[ChainId.MAINNET])
  
  const swap = async () => {
    if (!trade) return
    const amountIn = trade.maximumAmountIn(new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE))
    const amountOut = trade.minimumAmountOut(new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE))
    let tx = {} as any
    try {
      setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
      const router = getContract(hydraweb3RPC, addressRouter, AbiHydraV2Router01)
      const curA = currencies[Field.INPUT] as WrappedTokenInfo
      const isHydraA = curA.name === HYDRA.name
      if (showWrap) {
        tx =
          wrapType === WrapType.WRAP
            ? await deposit(isHydraA ? tokenB : tokenA, account, amountIn?.toExact())
            : await withdraw(isHydraA ? tokenB : tokenA, account, amountIn?.raw.toString())
      } else {
        const path = trade.route.path.map(token => getContract(hydraweb3RPC, token.address.replace('0x', '').toLocaleLowerCase(), AbiToken))
        if (!isEmptyObj(swapHydra)) {
          tx =
            swapHydra.address === tokenA.address
              ? await swapExactHYDRAForTokens(
                  router,
                  path,
                  amountIn?.raw.toString(),
                  amountOut?.raw.toString(),
                  account
                )
              : await swapExactTokensForHYDRA(
                  router,
                  path,
                  amountIn?.raw.toString(),
                  amountOut?.raw.toString(),
                  account
                )
        } else {
          tx = await swapExactTokensForTokens(
            router,
            path,
            amountIn?.raw.toString(),
            amountOut?.raw.toString(),
            account
          )
        }
      }
    } catch (e) {
      alert('Swap could not proceed, please try again in a few seconds.')
      setSwapState({
        attemptingTxn: false,
        tradeToConfirm,
        showConfirm: false,
        swapErrorMessage: undefined,
        txHash: undefined
      })
      return
    }
    if (walletErrorCatch(tx)) {
      return
    }
    if (transactions && transactions[tx.txid]) {
      alert('Already existing transaction hash generated, please try again in a few seconds.')
      setSwapState({
        attemptingTxn: false,
        tradeToConfirm,
        showConfirm: false,
        swapErrorMessage: undefined,
        txHash: undefined
      })
      return
    }
    setSwapState({
      attemptingTxn: false,
      tradeToConfirm,
      showConfirm: true,
      swapErrorMessage: undefined,
      txHash: tx.txid
    })

    const inputSymbol = trade.inputAmount.currency.symbol
    const outputSymbol = trade.outputAmount.currency.symbol
    const inputAmount = trade.inputAmount.toSignificant(3)
    const outputAmount = trade.outputAmount.toSignificant(3)

    const base = `Swap ${inputAmount} ${inputSymbol} for ${outputAmount} ${outputSymbol}`

    tx.hash = tx.txid
    addTransaction(tx, {
      summary: base
    })

    ReactGA.event({
      category: 'Swap',
      action:
        recipient === null
          ? 'Swap w/o Send'
          : (recipientAddress ?? recipient) === accountHydra
          ? 'Swap w/o Send + recipient'
          : 'Swap w/ Send',
      label: [currencies.INPUT?.symbol, currencies.OUTPUT?.symbol, Version.v1].join('/')
    })

    ReactGA.event({
      category: 'Routing',
      action: singleHopOnly ? 'Swap with multihop disabled' : 'Swap with multihop enabled'
    })
  }

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  
  const showApproveFlow =
    !swapInputError &&
    (approvalHydra === ApprovalState.NOT_APPROVED ||
      approvalHydra === ApprovalState.PENDING ||
      approvalHydra === ApprovalState.APPROVED) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  return (
    <>
      <TokenWarningModal
        isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
        tokens={importTokensNotInDefault}
        onConfirm={handleConfirmTokenWarning}
      />
      <SwapPoolTabs active={'swap'} />
      <AppBody>
        <SwapHeader />
        <Wrapper id="swap-page">
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={swap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <AutoColumn gap={'md'}>
            <CurrencyInputPanel
              label={independentField === Field.OUTPUT && !showWrap && trade ? 'From (estimated)' : 'From'}
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={!atMaxAmountInput}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              showCommonBases={true}
              id="swap-currency-input"
            />
            <AutoColumn justify="space-between">
              <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0 1rem' }}>
                <ArrowWrapper clickable>
                  <ArrowDown
                    size="16"
                    onClick={() => {
                      setApprovalSubmitted(false) // reset 2 step UI for approvals
                      onSwitchTokens()
                    }}
                    color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.primary1 : theme.text2}
                  />
                </ArrowWrapper>
                {recipient === null && !showWrap && isExpertMode ? (
                  <LinkStyledButton id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                    + Add a send (optional)
                  </LinkStyledButton>
                ) : null}
              </AutoRow>
            </AutoColumn>
            <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={handleTypeOutput}
              label={independentField === Field.INPUT && !showWrap && trade ? 'To (estimated)' : 'To'}
              showMaxButton={false}
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              id="swap-currency-output"
            />

            {recipient !== null && !showWrap ? (
              <>
                <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                  <ArrowWrapper clickable={false}>
                    <ArrowDown size="16" color={theme.text2} />
                  </ArrowWrapper>
                  <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                    - Remove send
                  </LinkStyledButton>
                </AutoRow>
                <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
              </>
            ) : null}

            {showWrap ? null : (
              <Card padding={showWrap ? '.25rem 1rem 0 1rem' : '0px'} borderRadius={'20px'}>
                <AutoColumn gap="8px" style={{ padding: '0 16px' }}>
                  {Boolean(trade) && (
                    <RowBetween align="center">
                      <Text fontWeight={500} fontSize={14} color={theme.text2}>
                        Price
                      </Text>
                      <TradePrice
                        price={trade?.executionPrice}
                        showInverted={showInverted}
                        setShowInverted={setShowInverted}
                      />
                    </RowBetween>
                  )}
                  {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                    <RowBetween align="center">
                      <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                        Slippage Tolerance
                      </ClickableText>
                      <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                        {allowedSlippage / 100}%
                      </ClickableText>
                    </RowBetween>
                  )}
                </AutoColumn>
              </Card>
            )}
          </AutoColumn>
          <BottomGrouping>
            {swapIsUnsupported ? (
              <ButtonPrimary disabled={true}>
                <TYPE.main mb="4px">Unsupported Asset</TYPE.main>
              </ButtonPrimary>
            ) : !accountHydra ? (
              <ButtonLight onClick={_toggleWalletModal}>Connect Wallet</ButtonLight>
            ) : showWrap ? (
              <ButtonPrimary
                disabled={Boolean(wrapInputError)}
                onClick={async () => {
                  if (!onWrap) {
                    return
                  }
                  const tx = await onWrap()
                  setSwapState({
                    tradeToConfirm: undefined,
                    attemptingTxn: false,
                    swapErrorMessage: undefined,
                    showConfirm: true,
                    txHash: tx.txid
                  })
                }}
              >
                {wrapInputError ??
                  (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
              </ButtonPrimary>
            ) : noRoute && userHasSpecifiedInputOutput ? (
              <GreyCard style={{ textAlign: 'center' }}>
                <TYPE.main mb="4px">Insufficient liquidity for this trade.</TYPE.main>
              </GreyCard>
            ) : showApproveFlow ? (
              <RowBetween>
                <ButtonConfirmed
                  onClick={approveCallbackHydra}
                  disabled={approvalHydra !== ApprovalState.NOT_APPROVED}
                  width="48%"
                  altDisabledStyle={approvalHydra === ApprovalState.PENDING} // show solid button while waiting
                  confirmed={approvalHydra === ApprovalState.APPROVED}
                >
                  {approvalHydra === ApprovalState.PENDING ? (
                    <AutoRow gap="6px" justify="center">
                      Approving <Loader stroke="white" />
                    </AutoRow>
                  ) : approvalHydra === ApprovalState.APPROVED ? (
                    'Approved'
                  ) : (
                    'Approve ' + currencies[Field.INPUT]?.symbol
                  )}
                </ButtonConfirmed>
                <ButtonError
                  onClick={() => {
                    // swap()
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      showConfirm: true,
                      txHash: undefined
                    })
                  }}
                  width="48%"
                  id="swap-button"
                  disabled={
                    !isValid ||
                    approvalHydra !== ApprovalState.APPROVED ||
                    (priceImpactSeverity > 3 && !isExpertMode) ||
                    noRoute
                  }
                  error={isValid && priceImpactSeverity > 2}
                >
                  <Text fontSize={16} fontWeight={500}>
                    {priceImpactSeverity > 3 && !isExpertMode
                      ? `Price Impact High`
                      : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                  </Text>
                </ButtonError>
              </RowBetween>
            ) : (
              <ButtonError
                onClick={() => {
                  setSwapState({
                    tradeToConfirm: trade,
                    attemptingTxn: false,
                    swapErrorMessage: undefined,
                    showConfirm: true,
                    txHash: undefined
                  })
                }}
                id="swap-button"
                disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode)}
                error={isValid && priceImpactSeverity > 2}
              >
                <Text fontSize={20} fontWeight={500}>
                  {swapInputError
                    ? swapInputError
                    : priceImpactSeverity > 3 && !isExpertMode
                    ? `Price Impact Too High`
                    : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                </Text>
              </ButtonError>
            )}
            {showApproveFlow && (
              <Column style={{ marginTop: '1rem' }}>
                <ProgressSteps steps={[approvalHydra === ApprovalState.APPROVED]} />
              </Column>
            )}
            {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
            {betterTradeLinkV2 && !swapIsUnsupported && toggledVersion === Version.v1 ? (
              <BetterTradeLink version={betterTradeLinkV2} />
            ) : toggledVersion !== DEFAULT_VERSION && defaultTrade ? (
              <DefaultVersionLink />
            ) : null}
          </BottomGrouping>

          {!accountHydra && (
            <ConnectWalletModal
              showConnectWalletModal={showConnectWalletModal}
              toggleConnectWalletModal={toggleConnectWalletModal}
            />
          )}
        </Wrapper>
      </AppBody>
      {!swapIsUnsupported ? (
        <AdvancedSwapDetailsDropdown trade={trade} />
      ) : (
        <UnsupportedCurrencyFooter show={swapIsUnsupported} currencies={[currencies.INPUT, currencies.OUTPUT]} />
      )}
    </>
  )
}
