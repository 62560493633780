import { contractCall } from './utils'

const _getReserves = 'getReserves',
  _balanceOf = 'balanceOf',
  _factory = 'factory',
  _token0 = 'token0',
  _token1 = 'token1',
  _price0CumulativeLast = 'price0CumulativeLast',
  _price1CumulativeLast = 'price1CumulativeLast',
  _kLast = 'kLast'

export const kLast = async (uniPair, walletExtension) => {
  const result = await contractCall(uniPair, _kLast, [], walletExtension.address || walletExtension)
  return result
}

export const price1CumulativeLast = async (uniPair, walletExtension) => {
  const result = await contractCall(uniPair, _price1CumulativeLast, [], walletExtension.address || walletExtension)
  return result
}

export const price0CumulativeLast = async (uniPair, walletExtension) => {
  const result = await contractCall(uniPair, _price0CumulativeLast, [], walletExtension.address || walletExtension)
  return result
}

export const token1 = async (uniPair, walletExtension) => {
  const result = await contractCall(uniPair, _token1, [], walletExtension.address || walletExtension)
  return result
}

export const token0 = async (uniPair, walletExtension) => {
  const result = await contractCall(uniPair, _token0, [], walletExtension.address || walletExtension)
  return result
}

export const factory = async (uniPair, walletExtension) => {
  const result = await contractCall(uniPair, _factory, [], walletExtension.address || walletExtension)
  return result
}

export const getReserves = async (uniPair, walletExtension) => {
  const reserves = await contractCall(uniPair, _getReserves, [], walletExtension.address || walletExtension)
  return reserves
}

export const balanceOf = async (uniPair, toAddress, walletExtension) => {
  const tx0 = await contractCall(uniPair, _balanceOf, [toAddress], walletExtension.address || walletExtension)
  return tx0
}
