import { contractCall, contractSend } from './utils'
import { fromAmountHYDRA } from './utils'

const _getAmountsOut = 'getAmountsOut',
  _getAmountsIn = 'getAmountsIn',
  _quote = 'quote',
  _addLiquidity = 'addLiquidity',
  _removeLiquidity = 'removeLiquidity',
  _addLiquidityHYDRA = 'addLiquidityHYDRA',
  _removeLiquidityHYDRA = 'removeLiquidityHYDRA',
  _swapExactTokensForTokens = 'swapExactTokensForTokens',
  _swapTokensForExactTokens = 'swapTokensForExactTokens',
  _swapExactHYDRAForTokens = 'swapExactHYDRAForTokens',
  _swapTokensForExactHYDRA = 'swapTokensForExactHYDRA',
  _swapExactTokensForHYDRA = 'swapExactTokensForHYDRA',
  _swapHYDRAForExactTokens = 'swapHYDRAForExactTokens'

// uint amountA, uint reserveA, uint reserveB
export const quote = async (uniRouter, amountA, reserveA, reserveB, walletExtension) => {
  const tx = await contractCall(uniRouter, _quote, [amountA, reserveA, reserveB], walletExtension.address)
  return tx
}

export const getAmountsOut = async (uniRouter, tokenA, tokenB, walletExtension, amountDesired) => {
  if (amountDesired > 1) {
    amountDesired = Math.round(amountDesired)
  }
  const tx = await contractCall(
    uniRouter,
    _getAmountsOut,
    [amountDesired, [tokenA.address, tokenB.address]],
    walletExtension.address
  )
  return tx
}

//   getAmountsIn(uint amountOut, address[] memory path)
export const getAmountsIn = async (uniRouter, tokenA, tokenB, walletExtension, amountDesired) => {
  if (amountDesired > 1) {
    amountDesired = Math.round(amountDesired)
  }
  const tx = await contractCall(
    uniRouter,
    _getAmountsIn,
    [amountDesired, [tokenA.address, tokenB.address]],
    walletExtension.address
  )
  return tx
}

export const addLiquidity = async (
  uniRouter,
  tokenA,
  tokenB,
  walletExtension,
  amountADesired,
  amountBDesired,
  amountAMin,
  amountBMin,
  noLiquidity
) => {
  const tx = await contractSend(
    uniRouter,
    _addLiquidity,
    [
      tokenA.address,
      tokenB.address,
      amountADesired,
      amountBDesired,
      amountAMin,
      amountBMin,
      walletExtension.address,
      20000000000
    ],
    walletExtension.address,
    noLiquidity ? 5000000 : 250000
  )
  return tx
}

export const removeLiquidity = async (uniRouter, tokenA, tokenB, walletExtension, tokenAAmount, tokenBAmount, lp) => {
  const tx = await contractSend(
    uniRouter,
    _removeLiquidity,
    [tokenA.address, tokenB.address, lp, tokenAAmount, tokenBAmount, walletExtension.address, 20000000000],
    walletExtension.address
  )
  return tx
}

export const addLiquidityHYDRA = async (
  uniRouter,
  token,
  walletExtension,
  amountTokenDesired,
  amountTokenMin,
  amountHYDRADesired,
  amountHYDRAMin,
  noLiquidity
) => {
  const tx = await contractSend(
    uniRouter,
    _addLiquidityHYDRA,
    [token.address, amountTokenDesired, amountTokenMin, amountHYDRAMin, walletExtension.address, 20000000000],
    walletExtension.address,
    noLiquidity ? 5000000 : 250000,
    amountHYDRADesired
  )
  return tx
}

export const removeLiquidityHYDRA = async (uniRouter, tokenA, walletExtension, lp) => {
  const tx = await contractSend(
    uniRouter,
    _removeLiquidityHYDRA,
    [tokenA.address, lp, 0, 0, walletExtension.address, 20000000000],
    walletExtension.address
  )
  return tx
}

export const swapExactTokensForTokens = async (uniRouter, path, tokenAAmount, tokenBMin, walletExtension) => {
  const _path = path.map(t => t.address)
  const tx = await contractSend(
    uniRouter,
    _swapExactTokensForTokens,
    [tokenAAmount, tokenBMin, _path, walletExtension.address, 20000000000],
    walletExtension.address
  )
  return tx
}

export const swapTokensForExactTokens = async (uniRouter, tokenA, tokenB, tokenAMax, tokenBAmount, walletExtension) => {
  const tx = await contractSend(
    uniRouter,
    _swapTokensForExactTokens,
    [tokenBAmount, tokenAMax, [tokenA.address, tokenB.address], walletExtension.address, 20000000000],
    walletExtension.address
  )
  return tx
}

export const swapTokensForExactHYDRA = async (uniRouter, wHydra, token, tokenAmount, wHydraMax, walletExtension) => {
  const tx = await contractSend(
    uniRouter,
    _swapTokensForExactHYDRA,
    [wHydraMax, tokenAmount, [token.address, wHydra.address], walletExtension.address, 20000000000],
    walletExtension.address
  )
  return tx
}

export const swapHYDRAForExactTokens = async (uniRouter, wHydra, token, tokenAmount, wHydraMin, walletExtension) => {
  const tx = await contractSend(
    uniRouter,
    _swapHYDRAForExactTokens,
    [tokenAmount, [wHydra.address, token.address], walletExtension.address, 20000000000],
    walletExtension.address,
    250000,
    fromAmountHYDRA(wHydraMin)
  )
  return tx
}

export const swapExactHYDRAForTokens = async (
  uniRouter,
  path,
  amountHydra,
  amountOutToken,
  walletExtension
) => {
  const _path = path.map(t => t.address)
  const tx = await contractSend(
    uniRouter,
    _swapExactHYDRAForTokens,
    [amountOutToken, _path, walletExtension.address, 20000000000],
    walletExtension.address,
    250000,
    fromAmountHYDRA(amountHydra)
  )
  return tx
}

export const swapExactTokensForHYDRA = async (
  uniRouter,
  path,
  amountToken,
  amountHydraMin,
  walletExtension
) => {
  const _path = path.map(t => t.address)
  const tx = await contractSend(
    uniRouter,
    _swapExactTokensForHYDRA,
    [amountToken, amountHydraMin, _path, walletExtension.address, 20000000000],
    walletExtension.address
  )
  return tx
}
