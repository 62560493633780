import Row from 'components/Row'
import React from 'react'
import styled from 'styled-components'
import { HideSmall } from 'theme'
import HydraGuard from '../assets/images/hydra-guard.png'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 550px;
  z-index: 5;
  width: 100%;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(2, 111, 194, 0.5), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 0px 30px rgba(2, 111, 194, 0.5);
  border-radius: 30px;
  /* padding: 1rem; */
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Row justify="center" align="center">
        <HideSmall>
          <img
            width={200}
            style={{ transform: 'scaleX(-1)', opacity: 0.15, marginRight: -45, marginTop: -40 }}
            src={HydraGuard}
          />
        </HideSmall>
        <BodyWrapper style={{ zIndex: 5 }}>{children}</BodyWrapper>
        <HideSmall>
          <img style={{ opacity: 0.15, marginLeft: -45, marginTop: -40 }} width={200} src={HydraGuard} />
        </HideSmall>
      </Row>
    </>
  )
}
