import React, { useContext } from 'react'
import { SwapPoolTabs } from 'components/NavigationTabs'
import { RowBetween } from 'components/Row'
import { TYPE } from 'theme'
import AppBody from 'pages/AppBody'
import { AutoColumn } from 'components/Column'
import styled, { ThemeContext } from 'styled-components'
import { LinkSecondary } from 'components/Link'
import { useStakingAPY } from 'hooks/useStakingAPY'
import { ethers } from 'ethers'
import { account } from 'hooks/useAddHydraAccExtension'
import { STAKING_TOKENS, STAKING_TOKEN_NAME } from 'constants/abis/staking'
import { useCurrency } from 'hooks/Tokens'
import { useStakingCurrency } from 'hooks/useStakingCurrency'

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

const Wrapper = styled.div`
  position: relative;
  padding: 0rem 1rem 1rem 1rem;
`

interface StakingTokenProps {
  currencyName: STAKING_TOKEN_NAME
}

function StakingToken({ currencyName }: StakingTokenProps) {
  const { apyPercentage } = useStakingAPY(ethers.utils.parseUnits('1', 18), currencyName)
  const { TOKEN_ADDRESS } = useStakingCurrency(currencyName)
  const currency = useCurrency(TOKEN_ADDRESS)
  if (!currency) {
    return null
  }

  return (
    <AutoColumn>
      <LinkSecondary
        padding="14px 20px"
        borderRadius="20px!important"
        href={`#/stake/${currencyName}`}
        style={{ justifyContent: 'space-between', fontSize: '22px' }}
      >
        <span>{currency?.name}</span>
        {account && apyPercentage ? (
          <span style={{ fontSize: '16px', color: 'white' }}>{`APY: ${apyPercentage}%`}</span>
        ) : (
          <span>{'>'}</span>
        )}
      </LinkSecondary>
    </AutoColumn>
  )
}

export default function Staking() {
  const theme = useContext(ThemeContext)
  return (
    <>
      <SwapPoolTabs active={'staking'} />
      <AppBody>
        <Wrapper>
          <AutoColumn gap="lg" justify="center">
            <AutoColumn gap="lg" style={{ width: '100%' }}>
              <TitleRow style={{ marginTop: '1rem', justifyContent: 'center' }} padding={'0'}>
                <TYPE.mediumHeader style={{ justifySelf: 'center' }}>Available for Staking</TYPE.mediumHeader>
              </TitleRow>
            </AutoColumn>
          </AutoColumn>
          <AutoColumn gap="md" style={{ marginTop: '16px' }}>
            {Object.keys(STAKING_TOKENS).map(key =>
              key !== 'default' ? <StakingToken key={key} currencyName={key as STAKING_TOKEN_NAME} /> : null
            )}
          </AutoColumn>
        </Wrapper>
      </AppBody>
    </>
  )
}
