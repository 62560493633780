import { useEffect, useState } from 'react'
import { getContract } from 'hydra/contracts/utils'
import { useHydraWalletAddress, hydraweb3RPC } from 'hooks/useAddHydraAccExtension'
import { addressFactory } from 'hydra/contracts/contractAddresses'
import { AbiHydraV2Factory, AbiToken, AbiHydraV2Pair } from 'hydra/contracts/abi'
import { getPair } from 'hydra/contracts/factoryFunctions'
import { Token } from 'hydra/sdk'
import { CallState, Result } from 'state/hydra/hrc20calls'
import { getReserves } from 'hydra/contracts/pairFunctions'
import { BigNumber } from 'ethers'
import { INVALID_ADDRESS_HYDRA } from '../constants'

export function useGetPairAddress(tokens: (Token | undefined)[][]): (string | undefined)[] {
  const [account] = useHydraWalletAddress()
  const [pairAddresses, setPairAddress] = useState<(string | undefined)[]>([])
  useEffect(() => {
    ;(async () => {
      const factory = getContract(hydraweb3RPC, addressFactory, AbiHydraV2Factory)
      const addresses: string[] = []
      for (let i = 0; i < tokens.length; i++) {
        const tokenA = tokens[i][0]
        const tokenB = tokens[i][1]
        if (!tokenA || !tokenB) {
          continue
        }
        try {
          const _tokenA = getContract(hydraweb3RPC, tokenA.address.slice(2).toLowerCase(), AbiToken)
          const _tokenB = getContract(hydraweb3RPC, tokenB.address.slice(2).toLowerCase(), AbiToken)
          const result = await getPair(factory, _tokenA, _tokenB, account)
          const r = result.executionResult?.formattedOutput?.[0]
          if (r === INVALID_ADDRESS_HYDRA || addresses.indexOf(r) > -1) {
            continue
          }
          addresses.push(r)
        } catch (e) {
          setPairAddress([])
        }
      }
      setPairAddress(addresses)
    })()
    return () => {
      setPairAddress([])
    }
  }, [JSON.stringify(tokens.length), account])
  return pairAddresses
}

const getResult = (reserve0: BigNumber, reserve1: BigNumber) => {
  const result: Result | undefined = []
  result['reserve0'] = reserve0
  result['reserve1'] = reserve1
  return result
}

export function useGetReserves(pairAddresses: (string | undefined)[]): CallState[] {
  const [account] = useHydraWalletAddress()

  const [reserves, setReserves] = useState<CallState[]>([
    { valid: false, result: undefined, loading: false, syncing: false, error: false }
  ] as CallState[])

  useEffect(() => {
    if (pairAddresses?.length < 1 || !account) return
    ;(async () => {
      const reserves: CallState[] = []
      for (let i = 0; i < pairAddresses.length; i++) {
        let result
        try {
          result = await getReserves(getContract(hydraweb3RPC, pairAddresses[i], AbiHydraV2Pair), account)
        } catch (e) {
          reserves[i] = {
            valid: false,
            result: undefined,
            loading: false,
            syncing: false,
            error: true
          }
          continue
        }

        const reserve0 = result.executionResult?.formattedOutput['_reserve0']
        const reserve1 = result.executionResult?.formattedOutput['_reserve1']

        reserves[i] = {
          valid: true,
          result: getResult(reserve0, reserve1),
          loading: false,
          syncing: false,
          error: false
        }
      }
      if (reserves.length > 0) setReserves(reserves)
    })()
    return () => {
      setReserves([{ valid: false, result: undefined, loading: false, syncing: false, error: false }] as CallState[])
    }
  }, [JSON.stringify(pairAddresses), account])

  return reserves
}
