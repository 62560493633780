import useAddHydraAccExtension, { isEmptyObj, useHydraAccount, useHydraLibrary } from 'hooks/useAddHydraAccExtension'
import { HYDRA, WHYDRA, Currency, ChainId, currencyEquals, TokenAmount } from 'hydra/sdk'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Plus } from 'react-feather'
import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { BlueCard, LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween, RowFlat } from '../../components/Row'

import { ROUTER_ADDRESS } from '../../constants'
import { PairState } from '../../data/Reserves'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState } from '../../hooks/useApproveCallback'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../state/mint/hooks'

import { useTransactionAdder } from '../../state/transactions/hooks'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { calculateSlippageAmount } from '../../utils'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import AppBody from '../AppBody'
import { Dots, Wrapper } from '../Pool/styleds'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'
import { currencyId } from '../../utils/currencyId'
import { PoolPriceBar } from './PoolPriceBar'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { getContract } from 'hydra/contracts/utils'
import { AbiToken, AbiHydraV2Router01, AbiWrappedHydra } from 'hydra/contracts/abi'
import { addressRouter } from 'hydra/contracts/contractAddresses'
import { addLiquidity, addLiquidityHYDRA } from 'hydra/contracts/routerFunctions'
import { allowance, approve } from 'hydra/contracts/tokenFunctions'
import useHydra from 'hooks/useHydra'
import { BigNumber, ethers } from 'ethers'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { walletErrorCatch } from 'state/hydra/walletErrorCatch'
import ConnectWalletModal from 'components/ConnectWalletModal'

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB }
  },
  history
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { walletExtension, hydraweb3Extension } = useHydra()
  useAddHydraAccExtension(walletExtension, hydraweb3Extension)
  const [account] = useHydraAccount()
  const [library] = useHydraLibrary()
  const chainId = ChainId.MAINNET

  const theme = useContext(ThemeContext)

  const [approvalAHydra, setApprovalAHydra] = useState(ApprovalState.UNKNOWN)
  const [approvalBHydra, setApprovalBHydra] = useState(ApprovalState.UNKNOWN)
  const [showConnectWalletModal, toggleConnectWalletModal] = useState(false)

  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)
  // usePairs([currencyA, currencyB])

  const _toggleWalletModal = () => {
    if (!window.ReactNativeWebView) toggleConnectWalletModal(true)
    toggleWalletModal()
  }

  const oneCurrencyIsHYDRA = Boolean(
    chainId &&
      ((currencyA && currencyEquals(currencyA, WHYDRA[chainId])) ||
        (currencyB && currencyEquals(currencyB, WHYDRA[chainId])))
  )

  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()

  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

  useEffect(() => {
    if (isEmptyObj(library) || !account?.loggedIn || !currencyA || !currencyB) {
      return
    }
    ;(async () => {
      try {
        const curA = currencyA as WrappedTokenInfo
        const curB = currencyB as WrappedTokenInfo

        if (currencyA === HYDRA) {
          setApprovalAHydra(ApprovalState.APPROVED)
        } else {
          const tokenA = getContract(library, curA.tokenInfo.address.toLowerCase(), AbiToken)
          const allowanceResultTA = await allowance(tokenA, account, addressRouter)
          const allowanceFormatedTA = allowanceResultTA.executionResult?.formattedOutput[0]
          setApprovalAHydra(
            BigNumber.from(allowanceFormatedTA.toString()).gt(0) ? ApprovalState.APPROVED : ApprovalState.NOT_APPROVED
          )
        }

        if (currencyB === HYDRA) {
          setApprovalBHydra(ApprovalState.APPROVED)
        } else {
          const tokenB = getContract(library, curB.tokenInfo.address.toLowerCase(), AbiToken)
          const allowanceResultTB = await allowance(tokenB, account, addressRouter)
          const allowanceFormatedTB = allowanceResultTB.executionResult?.formattedOutput[0]

          setApprovalBHydra(
            BigNumber.from(allowanceFormatedTB.toString()).gt(0) ? ApprovalState.APPROVED : ApprovalState.NOT_APPROVED
          )
        }
      } catch (e) {
        console.log('Error -> ', e)
      }
    })()
  }, [currencyA, currencyB, account, library])

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // ======== WORKAROUND! THIS SHOULD BE FIXED FROM EXTENSION ========
  useEffect(() => {
    setTimeout(() => {
      if (attemptingTxn) {
        setAttemptingTxn(false)
      }
    }, 5000)
  }, [attemptingTxn])
  // ======== WORKAROUND! THIS SHOULD BE FIXED FROM EXTENSION ========

  // txn values
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field])
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0')
      }
    },
    {}
  )

  const approveACallbackHydra = async () => {
    const curA = currencyA as WrappedTokenInfo
    const whydra = WHYDRA[chainId]

    if (currencyA === HYDRA) {
      setApprovalAHydra(ApprovalState.APPROVED)
    } else {
      const _tokenA = getContract(
        library,
        currencyA === whydra ? whydra.address : curA.tokenInfo.address.toLowerCase(),
        currencyA === whydra ? AbiWrappedHydra : AbiToken
      )
      const tx = await approve(ROUTER_ADDRESS, _tokenA, account, ethers.constants.MaxUint256)
      if (walletErrorCatch(tx)) {
        return
      }
      setApprovalAHydra(ApprovalState.APPROVED)
    }
  }

  const approveBCallbackHydra = async () => {
    const curB = currencyB as WrappedTokenInfo
    const whydra = WHYDRA[chainId]

    const _tokenB = getContract(
      library,
      currencyB === whydra ? whydra.address : curB.tokenInfo.address.toLowerCase(),
      currencyB === whydra ? AbiWrappedHydra : AbiToken
    )
    const tx = await approve(ROUTER_ADDRESS, _tokenB, account, ethers.constants.MaxUint256)
    if (walletErrorCatch(tx)) {
      return
    }
    setApprovalBHydra(ApprovalState.APPROVED)
    if (currencyA === HYDRA) {
      setApprovalAHydra(ApprovalState.APPROVED)
    }
  }

  const addTransaction = useTransactionAdder()

  const onAddHydra = async () => {
    try {
      if (!chainId || isEmptyObj(library) || !account?.loggedIn) {
        return
      }

      const router = getContract(library, ROUTER_ADDRESS, AbiHydraV2Router01)

      const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
      if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB) {
        return
      }

      const amountsMin = {
        [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
        [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0]
      }
      const curA = currencyA as WrappedTokenInfo
      const curB = currencyB as WrappedTokenInfo

      const _tokenA = getContract(
        library,
        currencyA === HYDRA ? WHYDRA[ChainId.MAINNET].address : curA.tokenInfo.address.toLowerCase(),
        currencyA === HYDRA ? AbiWrappedHydra : AbiToken
      )
      const _tokenB = getContract(
        library,
        currencyB === HYDRA ? WHYDRA[ChainId.MAINNET].address : curB.tokenInfo.address.toLowerCase(),
        currencyB === HYDRA ? AbiWrappedHydra : AbiToken
      )

      setAttemptingTxn(true)
      let tx
      if (currencyA === HYDRA || currencyB === HYDRA) {
        const tokenBIsHYDRA = currencyB === HYDRA
        tx = await addLiquidityHYDRA(
          router,
          tokenBIsHYDRA ? _tokenA : _tokenB,
          account,
          (tokenBIsHYDRA ? parsedAmountA : parsedAmountB).raw.toString(),
          amountsMin[tokenBIsHYDRA ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          (tokenBIsHYDRA ? parsedAmountB : parsedAmountA).toExact(),
          amountsMin[tokenBIsHYDRA ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          noLiquidity
        )
      } else {
        tx = await addLiquidity(
          router,
          _tokenA,
          _tokenB,
          account,
          parsedAmountA.raw.toString(),
          parsedAmountB.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          noLiquidity
        )
      }

      if (walletErrorCatch(tx)) {
        return
      }
      tx.hash = tx.id
      setAttemptingTxn(false)
      addTransaction(tx, {
        summary:
          'Add ' +
          parsedAmounts[Field.CURRENCY_A]?.toSignificant(3) +
          ' ' +
          currencies[Field.CURRENCY_A]?.symbol +
          ' and ' +
          parsedAmounts[Field.CURRENCY_B]?.toSignificant(3) +
          ' ' +
          currencies[Field.CURRENCY_B]?.symbol
      })
      setTxHash(tx.id)
      ReactGA.event({
        category: 'Liquidity',
        action: 'Add',
        label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/')
      })
    } catch (e) {
      console.log('Error -> ', e)
    }
  }

  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn gap="20px">
        <LightCard mt="20px" borderRadius="20px">
          <RowFlat>
            <Text fontSize="48px" fontWeight={500} lineHeight="42px" marginRight={10}>
              {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol}
            </Text>
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={30}
            />
          </RowFlat>
        </LightCard>
      </AutoColumn>
    ) : (
      <AutoColumn gap="20px">
        <RowFlat style={{ marginTop: '20px' }}>
          <Text fontSize="48px" fontWeight={500} lineHeight="42px" marginRight={10}>
            {liquidityMinted?.toSignificant(6)}
          </Text>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </RowFlat>
        <Row>
          <Text fontSize="24px">
            {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol + ' Pool Tokens'}
          </Text>
        </Row>
        <TYPE.italic fontSize={12} textAlign="left" padding={'8px 0 0 0 '}>
          {`Output is estimated. If the price changes by more than ${allowedSlippage /
            100}% your transaction will revert.`}
        </TYPE.italic>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAddHydra}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }

  const pendingText = `Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_A]?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencies[Field.CURRENCY_B]?.symbol}`

  const handleCurrencyASelect = useCallback(
    (currencyA: Currency) => {
      const newCurrencyIdA = currencyId(currencyA)
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/${currencyIdB}/${currencyIdA}`)
      } else {
        history.push(`/add/${newCurrencyIdA}/${currencyIdB}`)
      }
    },
    [currencyIdB, history, currencyIdA]
  )
  const handleCurrencyBSelect = useCallback(
    (currencyB: Currency) => {
      const newCurrencyIdB = currencyId(currencyB)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          history.push(`/add/${newCurrencyIdB}`)
        }
      } else {
        history.push(`/add/${currencyIdA ? currencyIdA : 'HYDRA'}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, history, currencyIdB]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
      formattedAmounts[Field.CURRENCY_A] = ''
      formattedAmounts[Field.CURRENCY_B] = ''
    }
    setTxHash('')
  }, [onFieldAInput, txHash, formattedAmounts])

  const isCreate = history.location.pathname.includes('/create')

  const addIsUnsupported = useIsTransactionUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  return (
    <>
      <AppBody>
        <AddRemoveTabs creating={isCreate} adding={true} />
        <Wrapper>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash}
            content={() => (
              <ConfirmationModalContent
                title={!noLiquidity ? 'You are creating a pool' : 'You will receive'}
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
            pendingText={pendingText}
          />
          <AutoColumn gap="20px">
            {noLiquidity ||
              (isCreate ? (
                <ColumnCenter>
                  <BlueCard>
                    <AutoColumn gap="10px">
                      <TYPE.link fontWeight={600} color={'primaryText1'}>
                        You are the first liquidity provider.
                      </TYPE.link>
                      <TYPE.link fontWeight={400} color={'primaryText1'}>
                        The ratio of tokens you add will set the price of this pool.
                      </TYPE.link>
                      <TYPE.link fontWeight={400} color={'primaryText1'}>
                        Once you are happy with the rate click supply to review.
                      </TYPE.link>
                    </AutoColumn>
                  </BlueCard>
                </ColumnCenter>
              ) : (
                <ColumnCenter>
                  <BlueCard>
                    <AutoColumn gap="10px">
                      <TYPE.link fontWeight={400} color={'primaryText1'}>
                        <b>Tip:</b> When you add liquidity, you will receive pool tokens representing your position.
                        These tokens automatically earn fees proportional to your share of the pool, and can be redeemed
                        at any time.
                      </TYPE.link>
                    </AutoColumn>
                  </BlueCard>
                </ColumnCenter>
              ))}
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onFieldAInput}
              onMax={() => {
                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
              }}
              onCurrencySelect={handleCurrencyASelect}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
              currency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokena"
              showCommonBases
            />
            <ColumnCenter>
              <Plus size="16" color={theme.text2} />
            </ColumnCenter>
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onCurrencySelect={handleCurrencyBSelect}
              onMax={() => {
                onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
              }}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
              currency={currencies[Field.CURRENCY_B]}
              id="add-liquidity-input-tokenb"
              showCommonBases
            />
            {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
              <>
                <LightCard padding="0px" borderRadius={'20px'}>
                  <RowBetween padding="1rem">
                    <TYPE.subHeader fontWeight={500} fontSize={14}>
                      {noLiquidity ? 'Initial prices' : 'Prices'} and pool share
                    </TYPE.subHeader>
                  </RowBetween>{' '}
                  <LightCard padding="1rem" borderRadius={'20px'}>
                    <PoolPriceBar
                      currencies={currencies}
                      poolTokenPercentage={poolTokenPercentage}
                      noLiquidity={noLiquidity}
                      price={price}
                    />
                  </LightCard>
                </LightCard>
              </>
            )}

            {addIsUnsupported ? (
              <ButtonPrimary disabled={true}>
                <TYPE.main mb="4px">Unsupported Asset</TYPE.main>
              </ButtonPrimary>
            ) : !account?.loggedIn ? (
              <ButtonLight onClick={_toggleWalletModal}>Connect Wallet</ButtonLight>
            ) : (
              <AutoColumn gap={'md'}>
                {(approvalAHydra === ApprovalState.NOT_APPROVED ||
                  approvalAHydra === ApprovalState.PENDING ||
                  approvalBHydra === ApprovalState.NOT_APPROVED ||
                  approvalBHydra === ApprovalState.PENDING) &&
                  isValid && (
                    <RowBetween>
                      {approvalAHydra !== ApprovalState.APPROVED && currencyIdA?.toUpperCase() !== HYDRA.symbol && (
                        <ButtonPrimary
                          onClick={approveACallbackHydra}
                          disabled={approvalAHydra === ApprovalState.PENDING}
                          width={
                            approvalBHydra !== ApprovalState.APPROVED && currencyIdA?.toUpperCase() === HYDRA.symbol
                              ? '48%'
                              : '100%'
                          }
                        >
                          {approvalAHydra === ApprovalState.PENDING ? (
                            <Dots>Approving {currencies[Field.CURRENCY_A]?.symbol}</Dots>
                          ) : (
                            'Approve ' + currencies[Field.CURRENCY_A]?.symbol
                          )}
                        </ButtonPrimary>
                      )}
                      {approvalBHydra !== ApprovalState.APPROVED && currencyIdB?.toUpperCase() !== HYDRA.symbol && (
                        <ButtonPrimary
                          onClick={approveBCallbackHydra}
                          disabled={approvalBHydra === ApprovalState.PENDING}
                          width={
                            approvalAHydra !== ApprovalState.APPROVED && currencyIdB?.toUpperCase() === HYDRA.symbol
                              ? '48%'
                              : '100%'
                          }
                        >
                          {approvalBHydra === ApprovalState.PENDING ? (
                            <Dots>Approving {currencies[Field.CURRENCY_B]?.symbol}</Dots>
                          ) : (
                            'Approve ' + currencies[Field.CURRENCY_B]?.symbol
                          )}
                        </ButtonPrimary>
                      )}
                    </RowBetween>
                  )}
                <ButtonError
                  onClick={() => {
                    setShowConfirm(true)
                  }}
                  disabled={
                    !isValid || approvalAHydra !== ApprovalState.APPROVED || approvalBHydra !== ApprovalState.APPROVED
                  }
                  error={!isValid}
                >
                  <Text fontSize={20} fontWeight={500}>
                    {error ?? 'Supply'}
                    {/* {'Supply'} */}
                  </Text>
                </ButtonError>
                {txHash && (
                  <RowBetween padding="1rem">
                    <TYPE.subHeader fontWeight={500} fontSize={14}>
                      Add Liquidity transaction id - {txHash}
                    </TYPE.subHeader>
                  </RowBetween>
                )}
              </AutoColumn>
            )}
          </AutoColumn>
        </Wrapper>
      </AppBody>
      {!addIsUnsupported ? (
        pair && !noLiquidity && pairState !== PairState.INVALID ? (
          <AutoColumn style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}>
            <MinimalPositionCard showUnwrapped={oneCurrencyIsHYDRA} pair={pair} />
          </AutoColumn>
        ) : null
      ) : (
        <UnsupportedCurrencyFooter
          show={addIsUnsupported}
          currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
        />
      )}

      {!account?.loggedIn && (
        <ConnectWalletModal
          showConnectWalletModal={showConnectWalletModal}
          toggleConnectWalletModal={toggleConnectWalletModal}
        />
      )}
    </>
  )
}
