import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Pair, JSBI } from 'hydra/sdk'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { BodyWrapper } from '../../pages/AppBody'
import FullPositionCard from '../../components/PositionCard'
import { useUserHasLiquidityInAllTokens } from '../../data/V1'
import { usePairBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { ExternalLink, TYPE, HideSmall, CloseIcon } from '../../theme'
import Card, { BlueCard } from '../../components/Card'
import Row, { RowBetween, RowFixed } from '../../components/Row'
import { ButtonPrimary, ButtonSecondary, ButtonEmpty } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { Dots } from '../../components/swap/styleds'
import { DataCard } from '../../components/earn/styled'
import { useStakingInfo } from '../../state/stake/hooks'
import { BIG_INT_ZERO } from '../../constants'
import useHydra from 'hooks/useHydra'
import useAddHydraAccExtension, { account as accountHydra } from 'hooks/useAddHydraAccExtension'
import HydraGuard from '../../assets/images/hydra-guard.png'
import WalletConnection from '../../assets/images/wallet-connection.png'

import { useWalletModalToggle } from 'state/application/hooks'
import { LinkPrimary, LinkSecondary } from 'components/Link'
import { Text, Web3StatusConnect } from 'components/Web3Status'
import { useTranslation } from 'react-i18next'

import ConnectWalletModal from 'components/ConnectWalletModal'
import { prefix } from 'utils'

const PageWrapper = styled(AutoColumn)`
  width: 100%;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  overflow: hidden;
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const ResponsiveButtonSecondary = styled(ButtonSecondary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export default function Pool() {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const { walletExtension, hydraweb3Extension } = useHydra()
  useAddHydraAccExtension(walletExtension, hydraweb3Extension)
  const toggleWalletModal = useWalletModalToggle()
  const [showLiquidityRewardMessage, toggleLiquidityRewardMessage] = useState(true)
  const [showConnectWalletModal, toggleConnectWalletModal] = useState(false)

  const account = accountHydra?.address
  const _toggleWalletModal = () => {
    if (!window.ReactNativeWebView) toggleConnectWalletModal(true)
    toggleWalletModal()
  }

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()

  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )

  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])

  const [v2PairsBalances, fetchingV2PairBalances] = usePairBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance

  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))

  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const hasV1Liquidity = useUserHasLiquidityInAllTokens()

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useStakingInfo()
  const stakingInfosWithBalance = stakingInfo?.filter(pool => JSBI.greaterThan(pool.stakedAmount.raw, BIG_INT_ZERO))
  const stakingPairs = usePairs(stakingInfosWithBalance?.map(stakingInfo => stakingInfo.tokens))

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter(v2Pair => {
    return (
      stakingPairs
        ?.map(stakingPair => stakingPair[1])
        .filter(stakingPair => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })

  return (
    <>
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />

        <Row justify="center" align="center">
          <HideSmall>
            <img
              width={200}
              style={{ transform: 'scaleX(-1)', opacity: 0.15, marginRight: -45, marginTop: -40 }}
              src={HydraGuard}
            />
          </HideSmall>
          <BodyWrapper style={{ padding: '0rem 1rem 1rem 1rem' }}>
            <AutoColumn gap="lg" justify="center">
              <AutoColumn gap="lg" style={{ width: '100%' }}>
                <TitleRow style={{ marginTop: '1rem', justifyContent: 'center' }} padding={'0'}>
                  <HideSmall>
                    <TYPE.mediumHeader style={{ justifySelf: 'center' }}>My liquidity</TYPE.mediumHeader>
                  </HideSmall>
                </TitleRow>
                {showLiquidityRewardMessage && (
                  <BlueCard style={{ position: 'relative' }}>
                    <TYPE.mediumHeader style={{ marginBottom: '1rem', fontWeight: 'bold' }}>
                      Liquidity Provider Rewards
                    </TYPE.mediumHeader>
                    <TYPE.link fontWeight={400} color={'primaryText1'}>
                      Liquidity providers earn a 0.3% fee on all trades proportional to their share of the pool. Fees
                      are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
                    </TYPE.link>
                    <CloseIcon
                      style={{ position: 'absolute', right: '1rem', top: '1.5rem' }}
                      onClick={() => toggleLiquidityRewardMessage(false)}
                    />
                  </BlueCard>
                )}

                {!account ? (
                  <Card padding="20px" paddingBottom="40px!important">
                    <TYPE.body color={theme.text3} textAlign="center">
                      Connect to a wallet to view your liquidity.
                    </TYPE.body>
                  </Card>
                ) : v2IsLoading ? (
                  <EmptyProposals>
                    <TYPE.body color={theme.text3} textAlign="center">
                      <Dots>Loading</Dots>
                    </TYPE.body>
                  </EmptyProposals>
                ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
                  <>
                    <ButtonSecondary>
                      <RowBetween>
                        <ExternalLink href={prefix + '/address/' + account}>
                          Account analytics and accrued fees
                        </ExternalLink>
                        <span> ↗</span>
                      </RowBetween>
                    </ButtonSecondary>
                    {v2PairsWithoutStakedAmount.map(v2Pair => (
                      <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                    ))}
                    {stakingPairs.map(
                      (stakingPair, i) =>
                        stakingPair[1] && ( // skip pairs that arent loaded
                          <FullPositionCard
                            key={stakingInfosWithBalance[i].stakingRewardAddress}
                            pair={stakingPair[1]}
                            stakedBalance={stakingInfosWithBalance[i].stakedAmount}
                          />
                        )
                    )}
                  </>
                ) : (
                  <EmptyProposals>
                    <TYPE.body color={theme.text3} textAlign="center">
                      No liquidity found.
                    </TYPE.body>
                  </EmptyProposals>
                )}
              </AutoColumn>
            </AutoColumn>
            <AutoColumn gap="md">
              <AutoColumn>
                {!account && (
                  <Web3StatusConnect
                    style={{ padding: 15 }}
                    id="connect-wallet"
                    onClick={_toggleWalletModal}
                    faded={!account}
                  >
                    <Text>{t('Connect to a wallet')}</Text>
                  </Web3StatusConnect>
                )}
              </AutoColumn>
              <AutoColumn gap="md">
                <RowBetween>
                  <LinkPrimary marginRight="10px" href="#/add/HYDRA">
                    Add Liquidity
                  </LinkPrimary>
                  <LinkSecondary
                    padding="18px"
                    marginRight="10px"
                    borderRadius="20px!important"
                    href={hasV1Liquidity ? '#/migrate/v1' : '#/find'}
                  >
                    {hasV1Liquidity ? 'Migrate' : 'Import'}
                  </LinkSecondary>
                  <LinkSecondary padding="18px" borderRadius="20px!important" href="#/create/HYDRA">
                    Create a Pair
                  </LinkSecondary>
                </RowBetween>
              </AutoColumn>
            </AutoColumn>
          </BodyWrapper>
          <HideSmall>
            <img style={{ opacity: 0.15, marginLeft: -45, marginTop: -40 }} width={200} src={HydraGuard} />
          </HideSmall>
          {!account && (
            <ConnectWalletModal
              showConnectWalletModal={showConnectWalletModal}
              toggleConnectWalletModal={toggleConnectWalletModal}
            />
          )}
        </Row>
      </PageWrapper>
    </>
  )
}
